<template>
  <Alerta />
  <Login />
  <div class="views">
    <component :is="layout" />
  </div>
</template>

<script setup>
import { computed, inject, onMounted, onBeforeMount, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStoreAlerta, useStoreRifas } from '@stores'
import { helperBloqueioBody, helperRedirectBrowser } from '@helpers'
import Alerta from '@components/global/alerta/Alerta.vue'
import Login from '@components/global/login/Login.vue'

const { currentRoute } = useRouter()

const emitter = inject('emitter')
const storeAlerta = useStoreAlerta()
const storeRifas = useStoreRifas()
const route = useRoute()

const layout = computed(() => {
  if (!currentRoute?.value?.name) return
  return currentRoute?.value.meta.layout || 'default-layout'
})

function mostrarAlerta(payload) {
  const dados = {
    visivel: true,
    mensagem: payload.mensagem,
    classe: payload.tipo
  }
  storeAlerta.mostrarAlerta(dados)
}

async function carregarRifa(hotlink) {
  await storeRifas.receberPorHotlink(hotlink)
  document.title = storeRifas.rifa?.titulo || 'Oficina das Rifas'
}

function detectarModalAberto() {
  setInterval(() => helperBloqueioBody.bloquearBodyQuandoAbrirModal(), 500)
}

function redirecionarBrowser() {
  helperRedirectBrowser.redirectAndroidInAppBrowsers()
}

onMounted(() => {
  detectarModalAberto()
  emitter.on('mostrarAlerta', (payload) => mostrarAlerta(payload))
  const hotlink = route.params.hotlink
  if (!hotlink) return
  carregarRifa(hotlink)
})

onBeforeMount(() => {
  redirecionarBrowser()
})

watch(
  () => route.params.hotlink,
  (newHotlink) => {
    if (!newHotlink) return
    carregarRifa(newHotlink)
  },
  { immediate: true }
)
</script>
